@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** Slide cards **/
.swiper-button-next {
  right: 0;
}
.swiper-button-prev {
  left: 0;
}
.swiper-button-next,
.swiper-button-prev {
  color: #b0b0b0;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 24px;
  font-weight: 900;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px;
}

.swiper-button-disabled {
  display: none;
}

#login-video {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 50vw;
  height: 100vh;
  object-fit: cover;
}

body,
html {
  height: 100%;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/* Custom Spinner */
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

i[class*='arrow'] {
  display: inline-block;
  width: 3.2rem;
  height: 3.2rem;
  border: 0.25rem solid white;
  border-bottom: 0;
  border-left: 0;
  border-radius: 1px;
  text-indent: -624.9375rem;
  position: absolute;
  top: 8px;
  right: 0;
}
i[class*='arrow'][class*='-blue'] {
  border-color: #2d86f7;
}
i[class*='arrow'][class*='-green'] {
  border-color: #86bb00;
}
i[class*='arrow'][class*='-orange'] {
  border-color: #ed9c00;
}

i[class*='arrow'][class*='-right'] {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
i[class*='arrow'][class*='-heavy'] {
  border-width: 0.25rem;
}
i[class*='arrow'][class*='-sm'] {
  width: 0.5rem;
  height: 0.5rem;
}

audio::-webkit-media-controls-panel {
  background-color: white;
}

audio {
  width: 100%;
}

.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-input label {
  position: relative;
  width: 130px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #047fc9;
  cursor: pointer;
  /* background-color: #004dd3; */
  font-size: 18px;
  font-weight: bold;
}

.file-input-small label {
  position: relative;
  width: 130px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #047fc9;
  cursor: pointer;
  /* background-color: #004dd3; */
  font-size: 13px;
  font-weight: bold;
}
.file-input label.disabled,
.file-input-small label.disabled {
  cursor: text !important;
}

.file-name {
  position: absolute;
  bottom: -35px;
  left: 10px;
  font-size: 0.85rem;
  color: #555;
}

.project-banner {
  width: 100%;
  height: auto;
}

.blue-card {
  background: linear-gradient(90deg, rgba(110, 162, 255, 1) 0%, rgba(0, 79, 212, 1) 100%);
}

.green-card {
  background: linear-gradient(90deg, rgba(0, 233, 139, 1) 0%, rgba(0, 191, 12, 1) 100%);
}

.red-card {
  background-color: '#FF5050';
}

table.table-ncs-breakdown {
  border: none;
}

table.table-ncs-breakdown th {
  text-align: left;
  border-bottom: 1px solid #efefef;
}

table.table-ncs-breakdown th,
table.table-ncs-breakdown td {
  padding: 10px;
}

table.table-ncs-breakdown td {
  text-transform: uppercase;
  font-weight: 500;
}

main {
  width: calc(100vw - 350px - 17px); /*350px is the width value for the left navbar*/
}

@keyframes fadeIn {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  30% {
    opacity: 0;
  }
  45% {
    transform: translateX(100%);
  }
  55% {
    transform: translateX(-100%);
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  30% {
    opacity: 0;
  }
  45% {
    transform: translateX(100%);
  }
  55% {
    transform: translateX(-100%);
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
